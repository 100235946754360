<template>
  <div>
    <v-container fluid class="px-6 py-1">
      <v-row class="mt-1">
        <div
          style="
            width: 380px;
            position: fixed;
            z-index: 2;
            transition: all 0.5s ease;
          "
          class="pa-1"
          ref="tagListMenu"
        >
          <!-- <div
            class="text-white"
            style="font-size: 15px; padding-left: 5px; padding-top: 3px"
          >
            标签列表
          </div> -->
          <v-card class="card-shadow border-radius-xl" v-show="!isManaging">
            <vxe-toolbar class="mx-5">
              <template v-slot:buttons>
                <vxe-input
                  v-model="filterName"
                  type="search"
                  placeholder="标签搜索"
                  @keyup="searchEvent"
                  style="width: 220px"
                ></vxe-input>
                <UserFilter
                  placeholder="跟踪人"
                  :autoSave="false"
                  inputWidth="100px"
                  class="mx-1"
                  @change="userSelect"
                  :returnId="true"
                ></UserFilter>

                <div class="mt-1">
                  <vxe-button
                    transfer
                    size="medium"
                    status="my-primary"
                    v-if="
                      $commonHelpers.getPermissions('tagStore.addOrDeleteTag')
                    "
                  >
                    <template #default>操作</template>
                    <template #dropdowns>
                      <!-- <vxe-button type="text" @click="tagAdd()"
                        >新增标签</vxe-button
                      > -->
                      <vxe-button type="text" @click="tagExportWithChildren()"
                        >导出标签带层级</vxe-button
                      >
                      <vxe-button type="text" @click="tagExport()"
                        >导出标签带股票</vxe-button
                      >
                      <!-- <vxe-button type="text" @click="tagManage()">管理标签</vxe-button> -->
                    </template>
                  </vxe-button>

                  <vxe-button
                    status="my-primary"
                    @click="isManaging = true"
                    class="mx-1"
                    >管理</vxe-button
                  >

                  <vxe-button
                    status="my-danger"
                    @click="isPool = !isPool"
                    class="mx-1"
                    >{{ isPool ? "打开层级" : "关闭层级" }}</vxe-button
                  >
                </div>
              </template>
            </vxe-toolbar>

            <TagListWithLevel
              ref="TagListWithLevel"
              :pageHeight="pageHeight"
              @tagClick="tagClick"
              v-show="!isPool"
            ></TagListWithLevel>

            <TagList
              ref="TagList"
              :pageHeight="pageHeight"
              @tagClick="tagClick"
              v-show="isPool"
            ></TagList>
          </v-card>

          <TagManage
            v-show="isManaging"
            @close="isManaging = false"
            @tagClick="tagClick"
            @tagAdd="tagAdd"
          ></TagManage>
        </div>
        <div
          style="
            width: calc(100vw - 500px);
            z-index: 1;
            background-color: #fff;
            margin-left: 390px;
          "
          class="py-3 px-2"
          :style="{ minHeight: this.pageHeight - 80 + 'px' }"
        >
          <div class="d-flex justify-space-between" v-if="appStore.tagDetail">
            <div class="d-flex">
              <div class="pt-1">
                <span
                  v-if="appStore.tagDetail.name"
                  class="px-2 py-0"
                  :class="getTagClass()"
                  style="border-radius: 5px; font-size: 13px"
                  >{{ appStore.tagDetail.name }}</span
                >
                <h2
                  class="text-h6 text-typo font-weight-bolder"
                  v-if="!appStore.tagDetail.name"
                >
                  请选择标签
                </h2>
              </div>

              <P class="text-typo ml-2 pt-2" style="font-size: 13px">
                跟踪人:{{
                  appStore.tagDetail.user_name
                    ? appStore.tagDetail.user_name
                    : "未设置"
                }}
              </P>
            </div>
            <!-- <div class="pt-1">
              <vxe-button transfer size="medium" status="my-primary">
                <template #default>操作</template>
                <template #dropdowns>
                  <vxe-button
                    type="text"
                    @click="tagEdit()"
                    v-if="$commonHelpers.getPermissions('tagStore.editTag')"
                    >修改</vxe-button
                  >
                  <vxe-button
                    type="text"
                    @click="deleteTag()"
                    v-if="
                      $commonHelpers.getPermissions('tagStore.addOrDeleteTag')
                    "
                    >删除</vxe-button
                  >
                </template>
              </vxe-button>
            </div> -->
          </div>
          <div style="" class="mr-2" v-if="currTag">
            <vxe-radio-group v-model="currTab" size="mini">
              <vxe-radio-button
                :label="0"
                content="基本信息"
              ></vxe-radio-button>
              <vxe-radio-button
                :label="1"
                content="逻辑脑图"
              ></vxe-radio-button>
              <!-- <vxe-radio-button :label="2" content="数据导入">
              </vxe-radio-button> -->
              <vxe-radio-button :label="3" content="资料库"></vxe-radio-button>
              <vxe-radio-button
                :label="4"
                content="标签观点"
              ></vxe-radio-button>
              <!-- <vxe-radio-button
                  :label="5"
                  content="思维导图"
                ></vxe-radio-button> -->
              <vxe-radio-button :label="5" content="信息流"></vxe-radio-button>
              <vxe-radio-button
                :label="6"
                content="信息来源"
              ></vxe-radio-button>
              <vxe-radio-button
                :label="7"
                content="标签智能"
              ></vxe-radio-button>
            </vxe-radio-group>
          </div>

          <div v-if="currTag" style="position: relative">
            <div v-show="currTab === 0" class="mt-3">
              <div>
                <div
                  style="
                    padding: 5px;
                    background-color: #bf0000;
                    color: #fff;
                    font-size: 25px;
                    text-align: center;
                    border-bottom: 1px solid #fff;
                  "
                >
                  {{ appStore.tagDetail.name + "估值表" }}
                  <vxe-button
                    @click="toFullScreen()"
                    size="medium"
                    style="
                      width: 80px;
                      padding: 0px;
                      position: absolute;
                      left: 5px;
                      background-color: #bf0000;
                      border-color: #fff;
                      color: #fff;
                    "
                  >
                    全屏
                  </vxe-button>
                  <div
                    style="
                      position: absolute;
                      right: 5px;
                      font-size: 13px;
                      top: 13px;
                    "
                  >
                    更新时间：{{
                      appStore.tagDetail.updated_at
                        ? appStore.tagDetail.updated_at.split(" ")[0]
                        : ""
                    }}
                  </div>
                </div>
                <ShowMoreContent
                  v-for="baseInfo in appStore.tagDetail.base_info"
                  :key="baseInfo.id"
                  :content_name="baseInfo.name"
                  :content_show="baseInfo.content_show"
                  :content_hide="baseInfo.content_hide"
                ></ShowMoreContent>
              </div>
              <ShowBindStock ref="ShowBindStock" :currTag="appStore.tagDetail">
              </ShowBindStock>
            </div>
            <div v-if="currTab === 1">
              <!-- <TagDataTable
                :tagDetail="appStore.tagDetail"
                v-if="forceUpdate"
              ></TagDataTable> -->
              <KnowledgeGraph
                :tagDetail="appStore.tagDetail"
                :viewHeight="pageHeight"
              ></KnowledgeGraph>
            </div>
            <div v-show="currTab === 2">
              <!-- <ExcelHistory :currTag="appStore.tagDetail"></ExcelHistory> -->
              <bindStock
                :currTag="appStore.tagDetail"
                style="height: 500px"
              ></bindStock>
            </div>
            <div v-if="currTab === 3" :key="appStore.tagDetail.id">
              <h3 class="text-body text-h6 font-weight-bolder pl-1 mb-2">
                内部文件库
              </h3>
              <BindFile
                :currTag="appStore.tagDetail"
                :fileTableHeight="350 + 'px'"
              ></BindFile>

              <h3 class="text-body text-h6 font-weight-bolder pl-1 mb-2">
                研报库
              </h3>
              <ResearchPaper :currTag="appStore.tagDetail"></ResearchPaper>

              <h3 class="text-body text-h6 font-weight-bolder pl-1 mb-2">
                电话会
              </h3>
              <TelephoneMeeting
                :currTag="appStore.tagDetail"
              ></TelephoneMeeting>
            </div>
            <div v-show="currTab === 4">
              <TagReview
                :noteList="appStore.tagDetail.notice"
                :height="pageHeight - 240 + 'px'"
                :key="appStore.tagDetail.id"
              ></TagReview>
            </div>

            <div v-if="currTab === 5" :key="appStore.tagDetail.id">
              <div style="border: 1px solid #ccc" class="mt-1">
                <InfoCenter :tagId="appStore.tagDetail.id"></InfoCenter>
              </div>
            </div>
            <div v-if="currTab === 6" :key="appStore.tagDetail.id">
              <InfoSource :currTag="appStore.tagDetail"></InfoSource>
            </div>
            <div v-if="currTab === 7" :key="appStore.tagDetail.id">
              <Ai></Ai>
            </div>
          </div>
          <div
            v-else
            style="
              width: 100%;
              height: calc(100% - 50px);
              flex-direction: column;
            "
            class="d-flex justify-center align-center"
          >
            <h3 class="text-dark" style="opacity: 0.8">标签指数排名</h3>

            <div
              style="z-index: 11; position: absolute; width: 100%; top: 10px"
              class="d-flex justify-center"
            >
              <a
                class="pr-4"
                v-for="item in barTabList"
                :key="item.url"
                :ripple="true"
                @click="currentBarTab = item.url"
                style="background-color: transparent; width: auto"
                :elevation="0"
              >
                <span
                  class="pa-0"
                  :class="{
                    'active-tab': currentBarTab == item.url,
                    'un-active-tab': currentBarTab != item.url,
                  }"
                  >{{ item.title }}</span
                >
              </a>
            </div>
            <ChartWithTable
              :currentBarTab="currentBarTab"
              :barData="barData"
              :isTable="false"
              :momentumDetail="momentumDetail"
              typeId="pool"
              :mini="true"
            ></ChartWithTable>
            <MomTimeLine style="width: 100%"></MomTimeLine>
            <FanChartTag :isTagIndex="true"></FanChartTag>
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";
import Stock from "@/api/stock.js";
import bindStock from "@/components/TagStore/bindStock";
import ShowBindStock from "@/components/TagStore/showBindStock";
import ExcelHistory from "@/components/TagStore/ExcelHistory";
import BindFile from "@/components/TagStore/BindFile";
import TagAdd from "@/components/TagStore/TagAdd";
import TagManage from "@/components/TagStore/TagManage";
import TagEdit from "@/components/TagStore/TagEdit";
import TagReview from "@/components/TagStore/TagReview";
import TagDataTable from "@/components/TagStore/TagDataTable";
import XEUtils from "xe-utils";
import ShowMoreContent from "@/components/TagStore/ShowMoreContent";
import ResearchPaper from "@/components/TagStore/ResearchPaper";
import InfoFlow from "@/components/TagStore/InfoFlow";
import TelephoneMeeting from "@/components/TagStore/TelephoneMeeting";
import KnowledgeGraph from "@/components/TagStore/KnowledgeGraph";
import TagList from "@/components/TagStore/TagList";
import TagListWithLevel from "@/components/TagStore/TagListWithLevel";
import InfoCenter from "@/views/InfoCenter.vue";
import Ai from "@/components/TagStore/Ai.vue";
import InfoSource from "@/components/TagStore/InfoSource.vue";
import FanChartTag from "@/components/ResonanceContrast/Radar/FanChartTag.vue";
import MomTimeLine from "@/views/Dashboard/MomTimeLine";
import ChartWithTable from "@/components/ResonanceContrast/Momentum/ChartWithTable";
import ResonanceContrast from "@/api/resonanceContrast.js";

import UserFilter from "@/components/StockPool/filter/UserFilter";
export default {
  name: "TagStore",
  components: {
    bindStock,
    ExcelHistory,
    BindFile,
    TagAdd,
    UserFilter,
    TagManage,
    TagReview,
    TagDataTable,
    ShowMoreContent,
    ShowBindStock,
    ResearchPaper,
    InfoFlow,
    TelephoneMeeting,
    KnowledgeGraph,
    TagList,
    TagListWithLevel,
    InfoCenter,
    Ai,
    InfoSource,
    FanChartTag,
    MomTimeLine,
    ChartWithTable,
  },
  data() {
    return {
      isManaging: false,
      isPool: true,
      filterName: "",
      mainTab: "tab-1",
      currTab: 0,

      currTag: "",
      tagDetail: {
        id: "",
        name: "",
        pinyin: "",
        level: "",
        desc: 0,
        comment: null,
        created_at: null,
        updated_at: null,
        content: null,
        color: "",
        stock: [],
        file: [],
      },

      bindFileTableLoading: false,

      pageHeight: 0,
      forceUpdate: true,

      refreshTimer: null,
      miniTimer: null,

      currentBarTab: 0,
      barTabList: [
        {
          title: "1日动量",
          url: 0,
        },
        {
          title: "3日动量",
          url: 1,
        },
        {
          title: "5日动量",
          url: 2,
        },
        {
          title: "10日动量",
          url: 3,
        },
        {
          title: "20日动量",
          url: 4,
        },
        {
          title: "60日动量",
          url: 5,
        },
      ],
      momentumDetail: [],
      barData: [],
    };
  },
  mounted() {
    this.pageHeight = document.documentElement.clientHeight;
    this.appStore.tagDetail = {
      id: "",
      name: "",
      pinyin: "",
      level: "",
      desc: 0,
      comment: null,
      created_at: null,
      updated_at: null,
      content: null,
      color: "",
      stock: [],
      file: [],
    };

    Bus.$on("refreshTagDetail", (e) => {
      if (this.currTag) {
        this.getTagDetail();
      }
    });

    if (this.$route.params.id) {
      this.currTag = this.$route.params.id;
      this.getTagDetail();
    }

    const myDiv = this.$refs.tagListMenu;

    ResonanceContrast.getMomentum("pool")
      .then((res) => {
        //console.log(res.data);
        this.barData = res.data.mom;
        this.momentumDetail = res.data.list;
      })
      .catch((err) => {
        console.log(err);
        this.showToast(err.msg, "danger", 10000);
      })
      .finally(() => {});
  },
  computed: {},
  watch: {},

  methods: {
    searchEvent() {
      this.$refs.TagList.searchEvent(this.filterName);
      this.$refs.TagListWithLevel.searchEvent(this.filterName);
    },
    getStockColor(row) {
      if (row.is_important) {
        return "#ff0000";
      } else if (row.pool) {
        return "#0000ee";
      } else {
        return "#000000";
      }
    },
    getTagClass() {
      if (this.appStore.tagDetail.level) {
        if (this.appStore.tagDetail.level === 1) {
          return "red lighten-5";
        } else if (this.appStore.tagDetail.level === 2) {
          return "orange lighten-5";
        } else if (this.appStore.tagDetail.level === 3) {
          return "green lighten-5";
        }
      } else {
        return "blue-grey lighten-5";
      }
    },

    tagClick({ row }) {
      //console.log(row);
      this.currTag = row.id;
      this.getTagDetail();
    },
    getTagDetail() {
      this.forceUpdate = false;
      this.showLoading("获取标签详情中，请稍候！");
      Tag.getTagDetail(this.currTag)
        .then((res) => {
          this.$refs.ShowBindStock.handleData(res.data);
          if (!res.data.source) {
            res.data.source = [];
          } else {
            res.data.source = JSON.parse(res.data.source);
          }
          this.appStore.tagDetail = res.data;
          this.forceUpdate = true;
          //console.log(this.appStore.tagDetail);

          clearInterval(this.refreshTimer);
          this.refreshStock();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    refreshStock() {
      let refreshMethod = () => {
        let groupList = this.appStore.tagDetail.stock;
        let stockList = [];
        groupList.forEach((group) => {
          group.children.forEach((stock, index) => {
            stockList.push(stock.code);

            stock.order = index;
            if (stock.order == group.children.length - 1) {
              stock.last = true;
            } else {
              stock.last = false;
            }
          });
        });
        Stock.getRealtimeData(stockList)
          .then((res) => {
            //console.log(res.data);
            //修改股票实时数据
            this.appStore.tagDetail.stock.forEach((group) => {
              group.children.forEach((stock) => {
                let stockData = res.data.filter(
                  (item) => item.code === stock.code
                );
                if (stockData.length > 0) {
                  if (stockData[0].price !== 0) {
                    stock.daily.close = stockData[0].price;
                    stock.applies.applies_day = stockData[0].applies;
                  }
                }
              });
            });
          })
          .catch((err) => {
            console.log(err);
            this.showToast(err.msg, "danger", 10000);
          });
      };

      refreshMethod();
      this.refreshTimer = setInterval(() => {
        refreshMethod();
      }, 60000);
    },
    tagAdd() {
      this.$layer.iframe({
        content: {
          content: TagAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["800px", "600px"],
        title: "新增标签(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        resize: true,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    tagManage() {
      this.$layer.iframe({
        content: {
          content: TagManage,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["800px", "600px"],
        title: "标签管理",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    tagEdit() {
      if (!this.currTag) {
        this.showToast("请先选择标签", "warning", 5000);
        return;
      }
      this.showLoading("获取标签详情中，请稍候！");
      Tag.getTagDetail(this.currTag)
        .then((res) => {
          this.$layer.iframe({
            content: {
              content: TagEdit,
              parent: this,
              data: { iframeData: { data: res.data } },
            },
            area: ["900px", "800px"],
            title: "修改标签(带*为必填)",
            maxmin: false,
            shade: true,
            shadeClose: false,
            resize: true,
            cancel: () => {
              //关闭弹窗事件
            },
          });
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteTag() {
      if (!this.currTag) {
        this.showToast("请先选择标签", "warning", 5000);
        return;
      }
      this.$layer.confirm("确定要删除标签吗？删除后将无法恢复！", (layerid) => {
        this.showLoading("删除标签中，请稍候");
        Tag.deleteTag(this.currTag)
          .then((res) => {
            this.showToast("已成功删除标签！", "success", 5000);
            Bus.$emit("refreshCoreStockPool", true);
            Bus.$emit("refreshTagList", true);
            this.currTag = "";
            this.appStore.tagDetail.name = "";
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    tagExport() {
      this.showLoading("导出中请稍候");
      Tag.tagExport()
        .then((res) => {
          window.location = res.data.url;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    tagExportWithChildren() {
      this.showLoading("导出中请稍候");
      Tag.tagExportWithChildren()
        .then((res) => {
          window.location = res.data.url;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    userSelect(val) {
      this.$refs.TagList.userSelect(val);
      this.$refs.TagListWithLevel.userSelect(val);
    },

    toFullScreen() {
      let routeUrl = this.$router.resolve({ name: "打印标签" });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
<style>
.v-application .pa-4 {
  padding-top: 0 !important;
}
.small-circle {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  top: 10px;
  left: 150px;
  z-index: 9999999999999 !important;
  background-color: #344767;
  opacity: 0.5;
}
</style>
